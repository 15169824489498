<template>
  <div class="student-home">
    <div class="class-content"
         :style="{'padding-left':classContentPadding+'px','padding-right':classContentPadding+'px'}">
      <div style="display: flex;flex-direction: row">
        <img src="../assets/go_home.png" v-if="user_type=='contact'"
             style="margin-right: 40px;width: 44px;height: 44px;cursor: pointer"
             @click="goHome">
        <p class="class-list" style="flex: 1">Official Guides</p>
        <div class="put-question" @click="goPutQuestion" v-if="user_type=='student'">
          <img src="../assets/icon_put_question.png" style="width: 22px;height: auto;margin-right: 5px">
          <p>Ask A Question</p>
        </div>
      </div>
      <div class="class-list-container" :style="{'padding-left':classLisContainerPaddingLeft+'px'}">
        <div v-for="(item,index) in dataList" :key="index" class="home-item">
          <div style="flex:1">
            <p class="class-name">{{ item.show_content }}</p>
            <el-progress v-if="item.show_type =='question'" :stroke-width="26" style="margin-top: 20px"
                         define-back-color="#E4E8F5"
                         :percentage="formatProgress(item.value)"
                         text-color="#538AFF"></el-progress>
          </div>
          <div v-if="item.show_type =='question'">
            <p class="exercise" @click="gotoExam(item.value)">Main Contents</p>
            <p class="error_list" style="margin-top: 15px" @click="goErrorList(item.value)" v-if="user_type=='student'">
              Wrong Answers</p>
          </div>
          <div v-else>
            <p class="check-btn" @click="checkMaterial(item.value)">Check</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserId, getUserType} from "../utils/store";
import {
  checkAndInitClassRecord,
  getContactClasses,
  getContactDataList,
  getDataList,
  getStudentClasses
} from "../api/fbla";
import '../assets/common/font.css'
import {Loading} from 'element-ui';

export default {
  name: "ClassList",
  data() {
    return {
      classContentPadding: 0,
      classLisContainerPaddingLeft: 0,
      user_type: getUserType(),
      user_id: getUserId(),
      dataList: [],
      loadingInstance: undefined
    }
  },
  mounted() {
    if (this.user_type == 'contact') {
      this.classLisContainerPaddingLeft = 84
      this.classContentPadding = 100
    } else {
      this.classContentPadding = 270
      this.classLisContainerPaddingLeft = 0
    }
    this.fetchData()
  },
  methods: {

    goHome() {
      this.$router.go(-1)
    },

    formatProgress(item) {
      let finished = item.finished
      let total = item.total
      if (total > 0) {
        let progress = Math.round((finished / total) * 100)
        return progress
      }
      return 0
    },

    checkMaterial(row) {
      window.open(row.link, '_blank')
    },

    goPutQuestion() {
      this.$router.push({
        path: '/put-question'
      })
    },
    goErrorList(row) {
      this.$router.push({
        path: '/errors',
        query: {
          class_id: row._id.$id
        }
      })
    },

    gotoExam(row) {
      if (row.class_record == null) {
        this.goCheckClass(row._id.$id)
      } else {
        if (row.class_record.data_valid == false) {
          this.$message.warning('This Class No Data！')
          return
        }
        // if (row.class_record.finished == true) {
        //   this.$message.warning('This Class Already Finished！')
        //   return
        // }
        this.gotoExamView(row._id.$id, row.class_record._id.$id)
      }
    },
    goCheckClass(class_id) {
      checkAndInitClassRecord(this.user_id, class_id).then((res) => {
        let result = res.data.data
        if (result.data_valid == false) {
          this.$message.warning('This Class No Data！')
          return
        }
        this.gotoExamView(class_id, result._id.$id)
      })
    },

    gotoExamView(class_id, record_id) {
      this.$router.push({
        path: '/exam',
        query: {
          class_id: class_id,
          record_id: record_id
        }
      })
    },
    async fetchData() {
      this.showLoadingView()
      this.dataList = []
      if (this.user_type == 'contact') {
        let class_response = await getContactClasses(this.user_id)
        let class_dic = {}
        if (class_response.data.code == 0) {
          let result = class_response.data.data
          for (let i = 0; i < result.length; i++) {
            let temp_class = result[i]
            let class_id = temp_class['_id']['$id']
            if (class_dic[class_id]) {
              continue
            }
            class_dic[class_id] = (i + 1)
            let temp = {
              show_content: result[i].class_info.class_name,
              show_type: 'question',
              value: temp_class
            }
            this.dataList.push(temp)
          }
        }
        let material_response = await getContactDataList(this.user_id)
        let material_result = material_response.data.data
        let material_dic = {};
        for (let i = 0; i < material_result.length; i++) {
          let material = material_result[i]
          let id = material['_id']['$id']
          if (material_dic[id]) {
            continue
          }
          material_dic[id] = (i + 1)
          let temp = {
            show_content: material.name,
            show_type: 'material',
            value: material
          }
          this.dataList.push(temp)
        }
        this.closeLoading()
        return
      }
      let class_response = await getStudentClasses(this.user_id)
      let material_response = await getDataList(this.user_id)
      let class_dic = {}
      if (class_response.data.code == 0) {
        let result = class_response.data.data
        for (let i = 0; i < result.length; i++) {
          let temp_class = result[i]
          let class_id = temp_class['_id']['$id']
          if (class_dic[class_id]) {
            continue
          }
          class_dic[class_id] = (i + 1)
          let temp = {
            show_content: temp_class.class_info.class_name,
            show_type: 'question',
            value: temp_class
          }
          this.dataList.push(temp)
        }
      }
      let material_result = material_response.data.data
      let material_dic = {};
      for (let i = 0; i < material_result.length; i++) {
        let material = material_result[i]
        let id = material['_id']['$id']
        if (material_dic[id]) {
          continue
        }
        material_dic[id] = (i + 1)
        let temp = {
          show_content: material.name,
          show_type: 'material',
          value: material
        }
        this.dataList.push(temp)
      }
      this.closeLoading()
    },
    showLoadingView() {
      this.closeLoading()
      this.loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.8)',
        spinner: 'el-icon-loading',
        text: 'Loading'
      });
    },
    closeLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close()
      }
    },
  }
}
</script>

<style scoped lang="less">

.class-content {
  width: 100%;
  padding: 60px 0px;
  box-sizing: border-box
}

.class-list-container {
  margin-top: 20px;
  box-sizing: border-box;
}


.student-home {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}


/deep/ .el-progress-bar__inner {
  height: 100%;
  background: linear-gradient(270deg, #538AFF 0%, #8EB3FF 100%);
}

.put-question {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 44px;
  background: #092E7F;
  border-radius: 22px 22px 22px 22px;
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: normal;
  color: #FFFFFF;
}

.exercise {
  width: 160px;
  height: 36px;
  background: #FFA900;
  border-radius: 18px 18px 18px 18px;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.error_list {
  cursor: pointer;
  text-align: center;
  width: 160px;
  height: 36px;
  line-height: 36px;
  background: #538AFF;
  border-radius: 18px 18px 18px 18px;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #FFFFFF;
}

.class-list {
  font-size: 28px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #092E7F;
}

.home-item {
  margin-bottom: 20px;
  width: 100%;
  height: 140px;
  background-color: #F2F6FF;
  background-image: url("../assets/home_item_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 35px 35px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 10px 10px;
}

.class-name {
  font-size: 24px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #092E7F;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.check-btn {
  cursor: pointer;
  text-align: center;
  width: 100px;
  height: 36px;
  line-height: 36px;
  background: #538AFF;
  border-radius: 18px 18px 18px 18px;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #FFFFFF;
}
</style>
